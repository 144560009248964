import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    MaterialTextField,
    MaterialSelect,
    MaterialCheckbox,
    MaterialSelectAsyncCreatable, MaterialDatePicker, MaterialSelectCreatable
} from "../../redux/FormsControls";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {createKart, createPosition, savePosition} from "../../redux/mainInterfaceReducer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

const Position = ({
    savePosition,
    ...props
}) => {
    return <Box my="20px">
        <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
                <Paper elevation={3}>
                    <Box px="5px">
                        <Grid container justify={'center'}>
                            <Grid container item xs={12} justify={'center'}>
                                <Typography variant="h6" style={{ marginTop: '10px', padding: '20px' }}>
                                    {props.formTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PositionReduxForm onSubmit={(data) => {savePosition(data, props.setShowForm, props.setLastUpdatedKartPosition, props.setCurrentUnit)}} enableReinitialize={true} {...props}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
};

const PositionForm = (props) => {
    const [workingTerm, setWorkingTerm] = useState({
        years: 0,
        months: 0,
        days: 0
    });

    const handleChangeWorkingterm = (prop) => (event) => {
        setWorkingTerm({ ...workingTerm, [prop]: event.target.value });
    };

    const workingTermCalc = () => {
        const minus_time=((workingTerm.days*1)+(workingTerm.months*30)+(workingTerm.years*365))*24*60*60000;
        const date = new Date(props.general_info.tarif_info.tarif_date);

        if (Date.parse(date)){
            const dateIso=new Date(date.getTime() - (date.getTimezoneOffset() * 60000) -  minus_time).toISOString();
            props.change('kart_position_date_start_work', dateIso);
        }else{
            const dateIso=new Date().toISOString();
            props.change('kart_position_date_start_work', dateIso);
        }

    };

    const [showFormKart, setShowFormKart] = useState(false);
    const [unitListAutofill, setUnitListAutofill] = useState(props.general_info.autofill_info.autofill_unit_list);
    const [showFormPosition, setShowFormPosition] = useState(false);
    const [showAdditional, setShowAdditional] = useState(false);



    return <form onSubmit={ props.handleSubmit} autoComplete="off">
        <Grid container>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid container item xs={12} justify={'center'}>
                        <Typography variant="caption" style={{marginTop: '2px', padding: '2px'}}>
                            Картка:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name={'id_kart'}
                            defaultValue={!!props.selectedKart ? {
                                value: props.selectedKart.kart_id,
                                label: props.selectedKart.kart_name
                            } : null}
                            loadOptions={props.searchKarts}
                            defaultOptions={!!props.selectedKart ? [{
                                value: props.selectedKart.kart_id,
                                label: props.selectedKart.kart_name
                            }] : null}
                            onCreateOption={(data) => {
                                props.createKart(data, setShowFormKart)
                            }}
                            options={props.kart_options}
                            fullWidth
                            autoFocus={false}
                            label='ПІБ'
                            component={MaterialSelectAsyncCreatable}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Accordion expanded={showFormKart} onChange={(e, s) => {
                            setShowFormKart(showFormKart ? false : true)
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Картка детально:
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container item spacing={2} alignItems="flex-start">
                                    <Grid item xs={12} md={12}>
                                        <Field fullWidth name="kart_name" component={MaterialTextField} label='ПІБ' />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field fullWidth name="kart_pol" component={MaterialSelect} label='Стать'
                                               options={[
                                                   {value: "0", label: 'Жіноча'},
                                                   {value: "1", label: 'Чоловіча'},
                                               ]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Field fullWidth name="kart_invalid" component={MaterialCheckbox}
                                               label='Інвалідність' />
                                    </Grid>
                                    <Grid container justify="center" style={{marginTop: '10px', padding: '20px'}}>
                                        <Field component="input" type="hidden" name='kart_id'/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid container item xs={12} justify={'center'}>
                        <Typography variant="caption" style={{marginTop: '2px', padding: '2px'}}>
                            Посада:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            defaultValue={{
                                value: props.selectedPosition.position_id,
                                label: props.selectedPosition.position_name
                            }}
                            name={'id_position'}
                            loadOptions={(inputValue, callback) => {props.searchPositions(inputValue, callback, props.currentUnit)}}
                            defaultOptions={!!props.selectedPosition ? [{
                                value: props.selectedPosition.position_id,
                                label: props.selectedPosition.position_name
                            }] : null}
                            onCreateOption={(data) => {
                                props.createPosition(data, setShowFormPosition, props.currentUnit, props.general_info.tarif_info.tarif_type)
                            }}
                            options={props.position_options}
                            fullWidth
                            label='Посада'
                            component={MaterialSelectAsyncCreatable}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Accordion expanded={showFormPosition} onChange={() => {
                            setShowFormPosition(showFormPosition ? false : true)
                        }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                Посада детально:
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container item xs={12} spacing={2} alignItems="flex-start">
                                    <Grid item xs={12} md={12}>
                                        <Field fullWidth name="position_name" component={MaterialTextField} label='Назва посади' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field fullWidth name={'position_group'} label='Група посади' placeholder='Група посади' component={MaterialSelect}  options={props.general_info.autofill_info.position_groups}/>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Field fullWidth name={"position_unit"} component={MaterialSelectCreatable} label='Підрозділ' options={unitListAutofill} onCreateOption={(newValue)=>{setUnitListAutofill(unitListAutofill.concat([{value : newValue, label : newValue}])); props.change('position_unit', newValue);}}/>
                                    </Grid>
                                    <Grid container justify="center" style={{marginTop: '10px', padding: '20px'}}>
                                        <Field component="input" type="hidden" name='id_tarif'/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container item xs={12} justify={'center'}>
            <Typography variant="h6" style={{marginTop: '10px', padding: '20px'}}>
                Інформація про позицію в тарифікаційному списку:
            </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="flex-start">
            <Grid item xs={12} md={3}>
                <Field autoFocus={true} fullWidth name="kart_position_category" component={MaterialTextField} label='Категорія'/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Field fullWidth name="kart_position_quantity" component={MaterialTextField} label='Кількість ставок'/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Field fullWidth name="kart_position_rank" component={MaterialTextField} label='Тарифний розряд'/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Field fullWidth name="kart_position_sub_unit" component={MaterialTextField} label='Відділ'/>
            </Grid>

        </Grid>

        <Grid item xs={12}>
            <Accordion expanded={showAdditional} onChange={(e, s) => {
                setShowAdditional(showAdditional ? false : true)
            }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    Додаткова інформація:
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container item spacing={2} alignItems="flex-start">
                        <Grid item xs={12} md={12}>
                            <Field fullWidth name="kart_position_director" component={MaterialCheckbox} label='Посада керівника' />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field fullWidth name="kart_position_part_time" component={MaterialCheckbox} label='Сумісництво' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field fullWidth name="kart_position_date_start_work" component={MaterialDatePicker} label='Початок стажу для вислуги років'/>
                        </Grid>
                        <Grid container item xs={12} md={6}>
                            <Grid container item xs={12} md={3}>
                                <IconButton color={'primary'} onClick={() => {
                                    workingTermCalc();
                                }}><AssignmentReturnIcon /></IconButton>
                            </Grid>
                            <Grid container item xs={12} md={3}>
                                <TextField id="years" label="Років" value={workingTerm.years} onChange={handleChangeWorkingterm('years')}/>
                            </Grid>
                            <Grid container item xs={12} md={3}>
                                <TextField id="months" label="Місяців" value={workingTerm.months} onChange={handleChangeWorkingterm('months')}/>
                            </Grid>
                            <Grid container item xs={12} md={3}>
                                <TextField id="days" label="Днів" value={workingTerm.days} onChange={handleChangeWorkingterm('days')}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field fullWidth name="kart_position_percent_from_director"
                                   component={MaterialSelect}
                                   options={props.directors_kart_positions}
                                   label='% від окладу керівника'
                                   clearable={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Field fullWidth name="fixed_accrual_1_sum" component={MaterialTextField} label='Фіксований посадовий оклад в грн.'/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
        <Grid container item xs={12} justify="center" style={{marginTop: '10px', padding: '20px'}}>
            <Field component="input" type="hidden" name='id_tarif'/>
            <Button variant="contained" onClick={() => {
                props.setShowForm(false)
            }} color={'secondary'}>Скасувати</Button>
            <Button variant="contained" type={'submit'} color={'primary'}>Зберегти</Button>
        </Grid>
    </form>
};

const PositionReduxForm = reduxForm(
    { form: 'kart-position-form'}/*,
    function(dispatch) {
        return {
            // This will be passed as a property to the presentational component
            changeFieldValue: function(field, value) {
                dispatch(change('kart-position-form', field, value))
            }
        }
    }*/
    )(PositionForm);

const mapStateToProps = (state) => ({
    isLoading               : state.tarif.isLoading,
    initialValues           : state.mainInterface.kart_position_model_for_form,
    selectedKart            : state.mainInterface.kart_model_for_form,
    selectedPosition        : state.mainInterface.position_model_for_form,
    directors_kart_positions: state.mainInterface.directors_kart_positions,
});

export default connect(mapStateToProps, {
                            savePosition,
                            createKart,
                            createPosition
                        })(Position);