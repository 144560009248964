import React from 'react';
import HeaderContainer from "./Components/Header/HeaderContainer"
import { Route, Switch } from "react-router";
import ukLocale from "date-fns/locale/uk";
import Login from "./Components/Login/Login";
import {Container} from "@material-ui/core";
import HomeScreenContainer from './Components/HomeScreen/HomeScreenContainer';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import MainInterfaceContainer from "./Components/MainInterface/MainInterfaceContainer";
import { ConfirmProvider } from 'material-ui-confirm';

function App(props) {
  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ukLocale}>
          <ConfirmProvider>
              <div className="app-wrapper">
                  <HeaderContainer/>
                  <Container>
                      <Switch>
                          <Route path="/login">
                              <Login/>
                          </Route>
                          <Route path="/home">
                              <HomeScreenContainer/>
                          </Route>
                          <Route path="/main-interface/:tarif_id" component={MainInterfaceContainer}/>
                      </Switch>
                  </Container>
              </div>
          </ConfirmProvider>
      </MuiPickersUtilsProvider>
  );
}

export default App;
