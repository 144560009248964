import React from 'react'
import { connect } from 'react-redux';
import {withAuthRedirect} from "../../utilities/withAuthRedirect";
import {compose} from "redux";
import {getOurRsList, setCurrentTarifDispatch, setRedirect} from "../../redux/tarifReducer";
import MainInterface from "./MainInterface";
import {Redirect} from "react-router-dom";
import {
    calc_list,
    getAccrualForPosition,
    getGeneralInfo,
    getPositionForUpdate,
    searchKarts,
    searchPositions,
    setEmptyPositionForCreate,
    setOpenLeftBar,
    deleteDataTarifTable, deleteKartPosition, setCurrentUnit,
} from "../../redux/mainInterfaceReducer";
import Loader from "../../utilities/Loader";
import {logout} from "../../redux/authReducer";
class MainInterfaceContainer extends React.Component{
    componentDidMount() {
        //Після редиректу з головного екрану - стерти цей параметр з стору
        if (!!this.props.redirect_url){
            this.props.setRedirect(null);
        }
        if (!!this.props.match.params.tarif_id) {
            this.props.setCurrentTarifDispatch(this.props.match.params.tarif_id);
            this.props.getGeneralInfo(this.props.match.params.tarif_id);
        }else{
            if (!!this.props.tarif_id) {
                this.props.getGeneralInfo(this.props.tarif_id);
            }
            else{
                console.log('No tarif id in store!!');
            }
        }
        this.props.getOurRsList();
    }
    render () {
        if (this.props.isLoading) {return <Loader />}
        if(!!this.props.match.params.tarif_id){
            let new_props={...this.props, tarif_id: this.props.match.params.tarif_id};
            return <MainInterface {...new_props}/>
        }else{
            return <Redirect to={'/home'} />
        }
    }
}
const mapStateToProps=(state) => ({
    login                               : state.auth.login,
    currentUnit                         : state.mainInterface.current_unit,
    open                                : state.mainInterface.left_bar_open,
    tarif_id                            : state.tarif.current_tarif,
    redirect_url                        : state.tarif.redirect,
    general_info                        : state.mainInterface.general_info,
    isLoading                           : state.mainInterface.isLoading,
    kart_list_for_autocomplete          : state.mainInterface.kart_list_for_autocomplete,
    position_list_for_autocomplete      : state.mainInterface.position_list_for_autocomplete,
    data_tarif_table                    : state.mainInterface.data_tarif_table
});

export default compose (connect(mapStateToProps,
    {   getGeneralInfo,
        logout,
        deleteDataTarifTable,
        getOurRsList,
        setRedirect,
        setCurrentTarifDispatch,
        setOpenLeftBar,
        searchKarts,
        setCurrentUnit,
        searchPositions,
        getPositionForUpdate,
        getAccrualForPosition,
        setEmptyPositionForCreate,
        calc_list,
        deleteKartPosition
    }), withAuthRedirect)(MainInterfaceContainer);