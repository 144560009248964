import React, {useState} from 'react'
import GridView from "../Common/GridViewTarifList";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import TarifForm from "./TarifForm";
import Modal from "@material-ui/core/Modal";

const HomeScreen = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [formTitle, setFormTitle] = useState('Створення нового тарифікаційного списку');
    return <div>
        <Grid container item xs={12} justify={'center'}>
            <Typography variant="h4" gutterBottom>
                Оберіть тарифікаційний список для роботи
            </Typography>
        </Grid>
        <Grid container item xs={12} justify={'center'} alignItems={'center'}>
            <Typography variant="h6" gutterBottom>
                або <Button variant="contained" onClick={() => {setFormTitle('Створення нового тарифікаційного списку');props.setTarifForCreate();setShowForm(true)}} color="primary">створіть новий</Button>
            </Typography>
        </Grid>
        {showForm ?
                <Modal
                    open={showForm}
                    onClose={() => setShowForm(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <div><TarifForm formTitle={formTitle}/></div>
                </Modal>
            : ''}
        <GridView
            tarif_types={props.tarif_types}
            setCurrentTarif={props.setCurrentTarifDispatch}
            getTarifForUpdate={props.getTarifForUpdate}
            deleteTarif={props.deleteTarif}
            setShowForm={setShowForm}
            setFormTitle={setFormTitle}
        />
    </div>
}

export default HomeScreen;