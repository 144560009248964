export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
};

export const  formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};

export const handleErrors = (response) => {
    if (!response.ok) {
        if (response.status===401){
            localStorage.removeItem('username');
            localStorage.removeItem('logged_in');
            localStorage.removeItem('expires');
            window.location="/login";
        }
        throw Error(response.statusText);
    }
    return response;
};

