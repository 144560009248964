import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import FunctionsIcon from "@material-ui/icons/Functions";
import Link from "@material-ui/core/Link";
import {handleErrors} from "../../utilities/functions";

let get_xls = (id, setLoadingXls, setXlsFileUrl) => {
    setLoadingXls(true);
    fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface/xls&tarif_id=${id}`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    })
        .then(response => handleErrors(response))//props.logout))
        .then(response => response.json())
        .then((file_url) => {
            setLoadingXls(false);
            setXlsFileUrl(file_url);
            return true;
        }
    ).catch(function (err) {
    if (err.message) {
        console.log(err);
        setLoadingXls(false);
        return false;
    }});


    // Helpers.httpRequest(
    //     `https://api.mastertarif.pp.ua/index.php?r=main-interface/xls&tarif_id=${id}`,
    //     'get',
    // )
    //     // 1. Convert the data into 'blob'
    //     .then((response) => response.blob())
    //     .then((blob) => {
    //
    //         // 2. Create blob link to download
    //         const url = window.URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `sample.${this.state.file}`);
    //         // 3. Append to html page
    //         document.body.appendChild(link);
    //         // 4. Force download
    //         link.click();
    //         // 5. Clean up and remove the link
    //         link.parentNode.removeChild(link);
    //         setLoadingXls(false);
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         setLoadingXls(false);
    //
    //     });
};

export default (props) => {
    const [loadingXls, setLoadingXls] = useState(false);
    const [xlsFileUrl, setXlsFileUrl] = useState(null);

    return <div>
        <Typography variant={'h4'} className={props.headerCaptionClass}>
            Оберіть підрозділ для початку роботи
        </Typography>
        <Typography variant={'h5'} className={props.headerCaptionClass}>
            або почніть створювати позицію тарифікаційного списку
        </Typography>
        <Typography variant={'h6'} className={props.headerCaptionClass}>
            {!!props.general_info.tarif_info ? `${props.general_info.tarif_info.tarif_name} з ${props.general_info.tarif_info.tarif_date} по ${props.general_info.tarif_info.tarif_date_for} стаття: ${props.general_info.tarif_info.our_rs_viddil}` : ''}
        </Typography>
        <Typography variant={'h6'}>
            Операції з тарифікаційним листом:
        </Typography>
        <p><IconButton onClick={() => {props.calc_list(props.tarif_id); }}><FunctionsIcon /></IconButton> Розрахунок всіх позицій</p>
        <p>
            <IconButton disabled={loadingXls} onClick={() => {get_xls(props.tarif_id, setLoadingXls, setXlsFileUrl)}}><FunctionsIcon /></IconButton>
            {loadingXls ? 'Формування файлу' : (xlsFileUrl===null ? 'Сформувати Ексель-файл ' : 'Формування Ексель-файлу завершено ')}
            {xlsFileUrl===null ? '' : <Link href={"https://api.mastertarif.pp.ua/xls/" + xlsFileUrl} color="primary"> Завантажити</Link>}
        </p>
        <br /><br /><br />
        <code>
            При создании карточки и указании тарифного разряда - автоматически добавлять посчитанный оклад и список надбавок (сделать отметку тех, которые не нужно автоматически добавлять - личные (за диплом з відзнакою, майстерність...)) с такой же должности?
            При открывании main-interface подгружать массив с [rank => oklad_summ] посчитанный на дату тарификации. (Или блокировать дату тарификации для изменения или дать функционал на пересчет всех окладов)
            При создании новой позиции по уже существующей должности в этом отделе, и наличии на ней вакансии - предлагать снять ставку с вакансии
        </code>
    </div>
}