import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ReactSelect from "react-select";
import FormGroup from "@material-ui/core/FormGroup";
import classNames from './FormControls.module.css';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {Button, Grid} from "@material-ui/core";

export const MaterialSelect = props => {
    const {
        meta: { error, touched },
        input: { onBlur, onChange, value, ...inputProps },
        ...others
    } = props;
    const displayBlock = {display: "block"};
    let show_clear_button= others.clearable===true && !(value === '' || value === null);
    return <FormGroup>
            <div>
                <Grid container>
                    <Grid item xs={show_clear_button ? 3 : false}>
                        {show_clear_button ? others.label : ''}
                    </Grid>
                    <Grid item xs={show_clear_button ? 3 : false}>
                        {show_clear_button ? <Button onClick={() => {onChange(null)}} variant="contained">Очистити поле</Button> : ''}
                    </Grid>
                    <Grid item xs={show_clear_button ? 6 : 12}>
                        <ReactSelect
                            value={(value === '') ? null : others.options.find(obj => obj.value === value)}
                            placeholder={others.label}
                            isSearchable={true}
                            onBlur={() => onBlur(value.value)}
                            onChange={(value) => {onChange(value.value)}}
                            {...others}
                            {...inputProps}
                        />
                    </Grid>
                </Grid>


            </div>
            <div className={classNames.invalidFeedback} style={displayBlock}>
                {error && touched ? error : ''}
            </div>
        </FormGroup>
};

export const MaterialSelectCreatable = props => {
    const {
        meta: { error, touched },
        input: { onBlur, onChange, onCreateOption, value, ...inputProps },
        ...others
    } = props;
    const displayBlock = {display: "block"};
    return <FormGroup>
        <div>
            <CreatableSelect
                onCreateOption={(inputValue) => {onCreateOption(inputValue);}}
                value={(value === '' || others.options===undefined) ? null : others.options.find(obj => obj.value === value)}
                placeholder={others.label}
                isSearchable={true}
                onBlur={() => onBlur(value.value)}
                onChange={(value) => {onChange(value.value)}}
                {...others}
                {...inputProps}
            />
        </div>
        <div className={classNames.invalidFeedback} style={displayBlock}>
            {error && touched ? error : ''}
        </div>
    </FormGroup>
};

export const MaterialSelectAsyncCreatable = props => {
    const {
        meta: { error, touched },
        input: { onBlur, onChange, onCreateOption, defaultOptions, loadOptions, value, ...inputProps },
        ...others
    } = props;
    const displayBlock = {display: "block"};

    return <FormGroup>
        <div>
            <AsyncCreatableSelect
                defaultOptions={defaultOptions}
                loadOptions={(inputValue, callback) => {loadOptions(inputValue, callback)}}
                onCreateOption={(inputValue) => {onCreateOption(inputValue)}}
                placeholder={others.label}
                isSearchable={true}
                onBlur={() => onBlur(value.value)}
                onChange={(value) => {onChange(value.value)}}
                value={(value === '') ? null : others.options.find(obj => obj.value === value)}
                {...others}
                {...inputProps}
            />
        </div>
        <div className={classNames.invalidFeedback} style={displayBlock}>
            {error && touched ? error : ''}
        </div>
    </FormGroup>
};



export const MaterialDatePicker = props => {
    const {
        meta: { submitting, error, touched },
        input: { onBlur, value, ...inputProps },
        ...others
    } = props;
    const onChange = date => {
        if (Date.parse(date)){
            let dateIso=new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
            inputProps.onChange(dateIso);
        }else{
            inputProps.onChange(null);
        }
    };
    return (
        <KeyboardDatePicker
            {...inputProps}
            {...others}
            autoOk
            format="yyyy-MM-dd"
            value={value && Date.parse(value) ? new Date(value).toISOString() : null}
            disabled={submitting}
            onBlur={() => {onBlur(value ? new Date(value).toISOString() : null)}}
            error={error && touched}
            onChange={onChange}
        />
    );
};

export const MaterialTextField = ({
           label,
           input,
           meta: { touched, invalid, error },
           ...custom
       }) => {
    return <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
};

export const MaterialCheckbox = ({ input, label }) => {
    let value=input.value;
    if (value==='0') value=false;
    if (value==='1') value=true;
    return <div>
        <FormControlLabel
            control={
                <Checkbox
                    checked={!!value}
                    onChange={input.onChange}
                    {...input}
                />
            }
            label={label}
        />
    </div>
};