import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from "./Header.styles"
import Button from '@material-ui/core/Button';
import {Link as RouterLink} from 'react-router-dom';
import {Link} from '@material-ui/core';

export default (props) => {
  const classes = makeStyles();
  return <div className={classes.header}>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          onClick={() => {props.setOpenLeftBar(true)}}
          color="inherit"
          aria-label="open drawer"
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          МастерТариф
        </Typography>
        { (props.isAuth & !!props.current_tarif) ? <Link to="/home" color="inherit" component={RouterLink} role='button'>Інший список</Link> : ''}
        {props.isAuth ? <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Пошук..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div> : ''
        }

        <div className={classes.loginArea}>
          {props.isAuth ?
              <Button color="inherit" onClick={props.logout}> <AccountCircleIcon /> Вихід ({props.login})</Button>
            :
              <Link to="/login" color="inherit" component={RouterLink} role='button'>Вхід</Link>
          }
        </div>
      </Toolbar>
    </AppBar>
  </div>
}