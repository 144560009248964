// import {getFormData} from "../utilities/functions";
//import React from "react";

import {getFormData, handleErrors} from "../utilities/functions";

let initialState = {
    isLoading                           : false,
    left_bar_open                       : true,
    general_info                        : {},
    data_tarif_table                    : null,
    current_unit                        : null,
    selected_kart_position              : {},
    accruals_model_for_form             : {},
    position_model_for_form             : {},
    kart_model_for_form                 : {},
    kart_position_model_for_form        : {},
    kart_position_accrual_model_for_form: {1:1},
    directors_kart_positions            : {},
    kart_list_for_autocomplete          : [],
    position_list_for_autocomplete      : [],
};
const ACTION_SET_AUTOFILL_UNIT_LIST             =   'ACTION_SET_AUTOFILL_UNIT_LIST';
const ACTION_SET_CURRENT_UNIT                   =   'ACTION_SET_CURRENT_UNIT';
const ACTION_SET_KART_POSITION_ACCRUAL_FORM     =   'ACTION_SET_KART_POSITION_ACCRUAL_FORM';
const ACTION_SET_DATA_TARIF_TABLE               =   'ACTION_SET_DATA_TARIF_TABLE';
const ACTION_SET_SELECTED_KART_POSITION         =   'ACTION_SET_SELECTED_KART_POSITION';
const ACTION_SET_LOADING                        =   'ACTION_SET_LOADING';
const ACTION_SET_LOADING_FINISHED               =   'ACTION_SET_LOADING_FINISHED';
const ACTION_SET_ERROR                          =   'ACTION_SET_ERROR';
const ACTION_SET_GENERAL_INFO                   =   'ACTION_SET_GENERAL_INFO';
const ACTION_SET_OPEN_LEFT_BAR                  =   'ACTION_SET_OPEN_LEFT_BAR';
const ACTION_SET_KART_MODEL_FOR_FORM            =   'ACTION_SET_KART_MODEL_FOR_FORM';
const ACTION_SET_KART_LIST_FOR_AUTOCOMPLETE     =   'ACTION_SET_KART_LIST_FOR_AUTOCOMPLETE';
const ACTION_SET_POSITION_LIST_FOR_AUTOCOMPLETE =   'ACTION_SET_POSITION_LIST_FOR_AUTOCOMPLETE';
const ACTION_SET_POSITION_MODEL_FOR_FORM        =   'ACTION_SET_POSITION_MODEL_FOR_FORM';
const ACTION_GET_POSITION_FOR_UPDATE            =   'ACTION_GET_POSITION_FOR_UPDATE';
const ACTION_SET_UNIT_LIST                      =   'ACTION_SET_UNIT_LIST';
const ACTION_SET_ACCRUALS                       =   'ACTION_SET_ACCRUALS';

const mainInterfaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_AUTOFILL_UNIT_LIST:
            return {
                ...state,
                general_info: {
                    ...state.general_info,
                    autofill_info: {
                        ...state.general_info.autofill_info,
                        autofill_unit_list : action.autofill_unit_list
                    }
                }};
        case ACTION_SET_CURRENT_UNIT:
            return {
                ...state,
                current_unit: action.current_unit
            };
        case ACTION_SET_KART_POSITION_ACCRUAL_FORM:
            return {
                ...state,
                kart_position_accrual_model_for_form: action.kart_position_accrual_model_for_form
            };
        case ACTION_SET_DATA_TARIF_TABLE:
            return {
                ...state,
                data_tarif_table: action.data_tarif_table
            };
        case ACTION_SET_SELECTED_KART_POSITION:
            return {
                ...state,
                selected_kart_position: action.selected_kart_position
            };
        case ACTION_SET_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ACTION_SET_OPEN_LEFT_BAR:
            return {
                ...state,
                left_bar_open: action.open,
                data_tarif_table: null
            };
        case ACTION_SET_LOADING_FINISHED:
            return {
                ...state,
                isLoading: false
            };
        case ACTION_SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case ACTION_SET_GENERAL_INFO:
            return {
                ...state,
                general_info: action.general_info,
            };
        case ACTION_SET_UNIT_LIST:
            return {
                ...state,
                general_info: {
                    ...state.general_info,
                    unit_list: action.unit_list
                },
            };
        case ACTION_SET_KART_MODEL_FOR_FORM:
            return {
                ...state,
                kart_model_for_form: action.data,
                kart_position_model_for_form: {
                    ...state.kart_position_model_for_form,
                    kart_name: !!action.data.kart_name ? action.data.kart_name : '',
                    kart_id: !!action.data.kart_id ? action.data.kart_id : null,
                    kart_invalid: !!action.data.kart_invalid ? action.data.kart_invalid : 0 ,
                }
            };
        case ACTION_SET_POSITION_MODEL_FOR_FORM:
            return {
                ...state,
                position_model_for_form: action.data,
                kart_position_model_for_form: {
                    ...state.kart_position_model_for_form,
                    position_name: !!action.data.position_name ? action.data.position_name : '',
                    position_id: !!action.data.position_id ? action.data.position_id : null,
                    position_group: !!action.data.position_group ? action.data.position_group : 0 ,
                    position_unit: !!action.data.position_unit ? action.data.position_unit : 0 ,
                }
            };
        case ACTION_SET_KART_LIST_FOR_AUTOCOMPLETE:
            return {
                ...state,
                kart_list_for_autocomplete: action.kart_list_for_autocomplete
            };
        case ACTION_SET_POSITION_LIST_FOR_AUTOCOMPLETE:
            return {
                ...state,
                position_list_for_autocomplete: action.position_list_for_autocomplete
            };
        case ACTION_SET_ACCRUALS:
            return {
                ...state,
                accruals_model_for_form: action.accruals_model_for_form
            };
        case ACTION_GET_POSITION_FOR_UPDATE:
            if (!action.kart_model_for_form) {
                return {
                    ...state,
                    position_model_for_form: action.position_model_for_form,
                    directors_kart_positions: action.directors_kart_positions,
                    kart_model_for_form: {},
                    kart_position_model_for_form: {
                        ...action.kart_position_model_for_form,
                        position_name: !!action.position_model_for_form.position_name ? action.position_model_for_form.position_name : '',
                        position_id: !!action.position_model_for_form.position_id ? action.position_model_for_form.position_id : null,
                        position_group: !!action.position_model_for_form.position_group ? action.position_model_for_form.position_group : 0,
                        position_unit: !!action.position_model_for_form.position_unit ? action.position_model_for_form.position_unit : 0,
                        kart_name: '',
                        kart_id: null,
                        kart_pol: null,
                        kart_invalid: 0,
                        fixed_accrual_1_sum: ''
                    },
                };
            } else {
                return {
                    ...state,
                    position_model_for_form: action.position_model_for_form,
                    directors_kart_positions: action.directors_kart_positions,
                    kart_model_for_form: !!action.kart_model_for_form ? action.kart_model_for_form : {},
                    kart_position_model_for_form: {
                        ...action.kart_position_model_for_form,
                        position_name: !!action.position_model_for_form.position_name ? action.position_model_for_form.position_name : '',
                        position_id: !!action.position_model_for_form.position_id ? action.position_model_for_form.position_id : null,
                        position_group: !!action.position_model_for_form.position_group ? action.position_model_for_form.position_group : 0,
                        position_unit: !!action.position_model_for_form.position_unit ? action.position_model_for_form.position_unit : 0,
                        kart_name: !!action.kart_model_for_form.kart_name ? action.kart_model_for_form.kart_name : '',
                        kart_id: !!action.kart_model_for_form.kart_id ? action.kart_model_for_form.kart_id : null,
                        kart_pol: !!action.kart_model_for_form.kart_id ? action.kart_model_for_form.kart_pol : null,
                        kart_invalid: !!action.kart_model_for_form.kart_invalid ? action.kart_model_for_form.kart_invalid : 0,
                        fixed_accrual_1_sum: !!action.fixed_accrual_1_sum ? action.fixed_accrual_1_sum : '',
                    },
                };
            }

        default:
            return state;
    }
};

export const setCurrentUnit = (current_unit) =>  ({type: ACTION_SET_CURRENT_UNIT, current_unit});
const setDataTarifTable = (data_tarif_table) =>  ({type: ACTION_SET_DATA_TARIF_TABLE, data_tarif_table});
const setSelectedKartPosition = (selected_kart_position) =>  ({type: ACTION_SET_SELECTED_KART_POSITION, selected_kart_position});
const setAccrualsForForm = (accruals_model_for_form) =>  ({type: ACTION_SET_ACCRUALS, accruals_model_for_form});
const setLoading = () => ({ type: ACTION_SET_LOADING });
export const setOpenLeftBar = (open) => {
    return {type: ACTION_SET_OPEN_LEFT_BAR, open}
};
const setUnitList  = (unit_list) => ({type: ACTION_SET_UNIT_LIST, unit_list});
const setAutofillUnitList  = (autofill_unit_list) => ({type: ACTION_SET_AUTOFILL_UNIT_LIST, autofill_unit_list});
const setGeneralInfo = (general_info) => ({type: ACTION_SET_GENERAL_INFO, general_info});
const setLoadingFinished = () => ({ type: ACTION_SET_LOADING_FINISHED });
const setKartModelForForm = (data) => ({
    type: ACTION_SET_KART_MODEL_FOR_FORM,
    data: data
});

export const setKartPositionAccrualModelForForm = (kart_position_accrual_model_for_form) => ({
    type: ACTION_SET_KART_POSITION_ACCRUAL_FORM,
    kart_position_accrual_model_for_form
});
export const setPositionModelForForm = (data) => ({
    type: ACTION_SET_POSITION_MODEL_FOR_FORM,
    data: data
});

export const deleteDataTarifTable = () => {
    return {type: ACTION_SET_DATA_TARIF_TABLE, data_tarif_table: null}
};

export const createKart = (data, setShowFormKart) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fcreate-kart`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData({kart_name: data})
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((kart_id) => {
                    dispatch(setKartModelForForm({kart_name: data, kart_id: kart_id, id_kart: kart_id}));
                    setShowFormKart(true);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }

        });

    }
};
export const createPosition = (data, setShowFormPosition, position_unit, position_tarif_type) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fcreate-position`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData({
                position_name: data,
                position_unit: position_unit,
                position_tarif_type: position_tarif_type
            })
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((position_id) => {
                    dispatch(setPositionModelForForm({position_name: data, position_id: position_id, position_unit: position_unit, position_group: 'Група'}));
                    setShowFormPosition(true);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};
const setKartList = (kart_list_for_autocomplete) => {
    return {type: ACTION_SET_KART_LIST_FOR_AUTOCOMPLETE, kart_list_for_autocomplete}
};
export const searchKarts = (value, callback, unit) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fsearch-kart`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData({kart_name: value, unit: unit})
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((kart_list) => {
                    dispatch(setKartList(kart_list));
                    return callback(kart_list);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }

        });

    }
};

export const setEmptyPositionForCreate = (id_tarif, kart_position_date_start_work) => ({
    type: ACTION_GET_POSITION_FOR_UPDATE,
    position_model_for_form         :   {},
    kart_model_for_form             :   {},
    kart_position_model_for_form    :   {id_tarif, kart_position_date_start_work},
});

export const getPositionForUpdate = (id, setShowForm) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface/get-position-for-update&id=${id}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((position_data) => {
                    dispatch({
                        type: ACTION_GET_POSITION_FOR_UPDATE,
                        position_model_for_form         :   position_data.model_position,
                        kart_model_for_form             :   position_data.model_kart,
                        kart_position_model_for_form    :   position_data.model_kart_position,
                        directors_kart_positions        :   position_data.models_directors_kart_positions,
                        fixed_accrual_1_sum             :   position_data.fixed_accrual_1_sum
                    });
                    setShowForm(true);
                }
            ).catch(function (err) {

        });
    }
};

const setPositionList = (position_list_for_autocomplete) => {
    return {type: ACTION_SET_POSITION_LIST_FOR_AUTOCOMPLETE, position_list_for_autocomplete}
};

export const searchPositions = (value, callback, unit) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fsearch-position`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData({position_name: value, unit})
        })
            .then(response => response.json())
            .then((position_list) => {
                dispatch(setPositionList(position_list));
                    return callback(position_list);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }

        });

    }
};

export const saveAccruals = (data) => {
    console.log(data);
    alert('saved');
};

export const savePosition = (data, setShowForm, setLastUpdatedKartPosition, setCurrentUnit) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fsave-kart-position`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData(data)
        })
            .then(response => response.json())
            .then((data) => {
                    dispatch(setUnitList (data.unit_list));
                    dispatch(setAutofillUnitList (data.autofill_unit_list));
                    setCurrentUnit(data.current_unit);
                    setLastUpdatedKartPosition(data.kart_position_id + '_' + Math.random().toFixed(2) );
                    setShowForm(false);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};

export const saveKartPositionAccrual = (data, id_position, setEditablePercentRow) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fsave-kart-position-accrual&id_position=${id_position}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData(data)
        })
            .then(response => response.json())
            .then((accruals_model_for_form) => {
                    dispatch(setAccrualsForForm(accruals_model_for_form));
                    setEditablePercentRow(false);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};

/*export const calcOne = (id) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fcalc-one&id=${id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((result) => {
                    alert(result);
                }
            ).catch(function (err) {

        });
    }
};*/


export const calc_list = (id) => {
    return (dispatch) => {
        dispatch(setLoading());
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fcalc-tarif-list&id=${id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            // body: getFormData(data)
        })
            .then(response => response.json())
            .then((data) => {
                    dispatch(setDataTarifTable(data));
                    dispatch(setLoadingFinished());
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
            dispatch(setLoadingFinished());
        });
    }
};

export const getGeneralInfo = (id) => {
    return (dispatch) => {
        dispatch(setLoading());
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fget-general-info&id=${id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            // body: getFormData(data)
        })
            .then(response => response.json())
            .then((general_info) => {
                    dispatch(setGeneralInfo(general_info));
                    dispatch(setLoadingFinished());
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
            dispatch(setLoadingFinished());
        });
    }
};

export const deleteKartPosition = (kart_position_id, setLastQuery, setLoading) => {
    return (dispatch) => {
        let action='delete-kart-position';
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2F${action}&kart_position_id=${kart_position_id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((result) => {
                    setLastQuery(null);
                    setLoading(false);
                    console.log(result);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
}

export const deleteAccrualFromPosition = (kart_accrual_id) => {
    return (dispatch) => {
        let action='delete-kart-accrual';
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2F${action}&kart_accrual_id=${kart_accrual_id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((accruals_model_for_form) => {
                    dispatch(setAccrualsForForm(accruals_model_for_form));
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};

export const addAccrualToPosition = (id_accrual, id_kart_position) => {
    return (dispatch) => {
        let action='add-accrual-to-kart-position';
        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2F${action}&id_accrual=${id_accrual}&id_kart_position=${id_kart_position}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((accruals_model_for_form) => {
                    dispatch(setAccrualsForForm(accruals_model_for_form));
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};
export const getAccrualForPosition = (id, setShowAccrualForm, calc=false) => {
    return (dispatch) => {
        dispatch (setSelectedKartPosition(id));
        let action='get-accruals-for-position';
        if (calc) action='calc-kart-position-accruals';

        fetch(`https://api.mastertarif.pp.ua/index.php?r=main-interface%2F${action}&id=${id}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => response.json())
            .then((accruals_model_for_form) => {
                    dispatch(setAccrualsForForm(accruals_model_for_form));
                    setShowAccrualForm(true);
                }
            ).catch(function (err) {
            if (err.message) {
                console.log(err);
                return false;
            }
        });
    }
};
export default mainInterfaceReducer;