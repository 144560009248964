import React from "react";
import Paper from "@material-ui/core/Paper";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {MaterialTextField, MaterialDatePicker, MaterialSelect} from "../../redux/FormsControls";
import {required} from "../../utilities/validators";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {saveTarif} from "../../redux/tarifReducer";

const Tarif = ({
    saveTarif,
    ...props
}) => {
    return <Box my="20px">
        <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
                <Paper elevation={3}>
                    <Box px="5px">
                        <Grid container justify={'center'}>
                            <Grid container item xs={12} justify={'center'}>
                                <Typography variant="h6" >
                                    {props.formTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TarifReduxForm onSubmit={saveTarif} enableReinitialize={true} {...props}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
};

const TarifForm = (props) => {
    return <form onSubmit={props.handleSubmit} autoComplete="off">
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                    <Field autoFocus={true} fullWidth name={'tarif_name'} label='Назва тарифікації' component={MaterialTextField} validate={[required]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field fullWidth name="tarif_date" component={MaterialDatePicker} label='З дати' validate={[required]}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Field fullWidth name="tarif_date_for" component={MaterialDatePicker} label='По дату' validate={[required]}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field fullWidth name={'id_our_rs'} label='Стаття фінансування' placeholder='Стаття фінансування' component={MaterialSelect} validate={[required]} options={props.ourRsList}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field fullWidth name={'tarif_type'} label='Тип тарифікації' placeholder='Тип тарифікації' component={MaterialSelect} validate={[required]} options={props.typeTarifList}/>
                </Grid>
                <Grid container justify="center" style={{marginTop: '10px', padding: '20px'}}>
                    <Field component="input" type="hidden" name='tarif_id' />
                    <Button variant="contained" type={'submit'} color={'primary'}>Зберегти</Button>
                </Grid>
            </Grid>
        </form>
};

const TarifReduxForm = reduxForm({ form: 'tarif-form'})(TarifForm);

const mapStateToProps = (state) => ({
    isLoading: state.tarif.isLoading,
    ourRsList: state.tarif.ourRsList,
    typeTarifList: state.tarif.typeTarifList,
    initialValues: state.tarif.tarif_model_for_form
});

export default connect(mapStateToProps, { saveTarif })(Tarif);