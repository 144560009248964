import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {
    PagingState,
    SortingState,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import Loading from "../../utilities/Loader";
import {ActionsColumn} from "./ActionsColumn";
import { useHistory } from "react-router-dom";
import { useConfirm } from 'material-ui-confirm';
import {handleErrors} from "../../utilities/functions";

const URL = 'https://api.mastertarif.pp.ua/index.php?r=tbl-tarif%2Fget-list-tarif';

export default (props) => {
    const [columns] = useState([
        { name: 'tarif_name', title: 'Назва' },
        {
            name: 'tarif_type',
            title: 'Тип',
            getCellValue: row => (row.tarif_type ? props.tarif_types[row.tarif_type] : undefined),
        },
        { name: 'tarif_date', title: 'З дати' },
        { name: 'tarif_date_for', title: 'По дату' },
        { name: 'our_rs_rs', title: 'Стаття фінансування' }
    ]);
    const [rows, setRows] = useState([]);
    const [sorting, setSorting] = useState([{ columnName: 'tarif_date', direction: 'asc' }]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 25, 50, 100]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState();

    const changePageSize = (value) => {
        const totalPages = Math.ceil(totalCount / value);
        const updatedCurrentPage = Math.min(currentPage, totalPages - 1);

        setPageSize(value);
        setCurrentPage(updatedCurrentPage);
    };

    const getQueryString = () => {
        let queryString = `${URL}&take=${pageSize}&skip=${pageSize * currentPage}`;

        if (sorting.length) {
            const sortingConfig = sorting
                .map(({ columnName, direction }) => ({
                    selector: columnName,
                    desc: direction === 'desc',
                }));
            const sortingStr = JSON.stringify(sortingConfig);
            queryString = `${queryString}&sort=${escape(`${sortingStr}`)}`;
        }
        return queryString;
    };

    const loadData = () => {
        const queryString = getQueryString();
        if (queryString !== lastQuery && !loading) {
            setLoading(true);
            fetch(queryString, {credentials: 'include'})
                .then(response => handleErrors(response))//props.logout))
                .then(response => response.json())
                .then(({ data, totalCount: newTotalCount }) => {
                    setRows(data);
                    setTotalCount(newTotalCount);
                    setLoading(false);
                })
                .catch(
                    (error) => {
                        console.log(error);
                        setLoading(false)
                    }

                );
            setLastQuery(queryString);
        }
    };

    useEffect(() => loadData());
    let history = useHistory();
    let confirm = useConfirm();

    const handleDelete= (id) => {
        confirm({
                    description: 'Підвердження видалення запису',
                    title: 'Ви впевнені?', confirmationText:'Підтвердити',
                    cancellationText:'Скасувати',
                    confirmationButtonProps:{endIcon:<DeleteIcon />}
                }
            )
            .then(() => {setLoading(true);setLastQuery(null); props.deleteTarif(id, setLoading);})
            .catch(() => { /* ... */ });
    };
    const actions = [
        {
            icon: <DeleteIcon/>,
            action: (id) => {handleDelete(id)}
        },
        {
            icon: <EditIcon/>,
            action: (id) => {props.setFormTitle('Редагування тарифікаційного списку'); props.getTarifForUpdate(id);props.setShowForm(true)}
        },
        {
            icon: <PlayCircleOutlineIcon />,
            action: (id) => {history.push(`/main-interface/${id}`)}
        },

    ];
    const rowComponent = ({ ...restProps }) => {
        return <Table.Row {...restProps} style={{ height: "40px" }} />;
    };
    const cellComponent = ({ ...restProps }) => {
        return <Table.Cell {...restProps} style={{ paddingTop: "2px", paddingBottom: "2px" }} />;
    };


    return (
        <Paper style={{ position: 'relative' }} elevation={3} >
            {loading && <Loading />}
            <Grid
                rows={rows}
                columns={columns}
            >
                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={changePageSize}
                />
                <CustomPaging
                    totalCount={totalCount}
                />
                <Table rowComponent={rowComponent} cellComponent={cellComponent}/>
                <TableHeaderRow showSortingControls contentProps={{align:'center'}} />
                <ActionsColumn actions={actions} width={160} primaryKey={'tarif_id'}/>
                <PagingPanel
                    pageSizes={pageSizes}
                />
            </Grid>
        </Paper>
    );
};
