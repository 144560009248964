import React from 'react'
import Header from './Header'
import {connect} from "react-redux"
import { logout } from './../../redux/authReducer';
import {setOpenLeftBar} from "../../redux/mainInterfaceReducer";

class HeaderContainer extends React.Component{
    render () {
        return <Header {...this.props}/>
    }
}
const mapStateToProps=(state) => ({
    isAuth: state.auth.isAuth,
    login: state.auth.login,
    current_tarif: state.tarif.current_tarif,
    data_tarif_table: state.mainInterface.data_tarif_table
});

export default connect(mapStateToProps, {
    logout, setOpenLeftBar
})(HeaderContainer);