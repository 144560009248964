import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import { TABLE_HEADING_TYPE } from '@devexpress/dx-grid-core'
import {Getter, Template, Plugin} from '@devexpress/dx-react-core'
import {
    Table,
} from '@devexpress/dx-react-grid-material-ui'

const pluginDependencies = [
    {name: 'Table'},
];

const ACTIONS_COLUMN_TYPE = 'actionsColumnType';

function tableColumnsWithActions(tableColumns, width) {
    return [...tableColumns, {key: ACTIONS_COLUMN_TYPE, type: ACTIONS_COLUMN_TYPE, width: width}];
}

function isHeadingActionsTableCell(tableRow, tableColumn) {
    return tableRow.type === TABLE_HEADING_TYPE && tableColumn.type === ACTIONS_COLUMN_TYPE;
}

function isActionsTableCell(tableRow, tableColumn) {
    return tableRow.type !== TABLE_HEADING_TYPE && tableColumn.type === ACTIONS_COLUMN_TYPE;
}

export class ActionsColumn extends React.PureComponent {
    render() {
        const {
            actions,
            width,
            primaryKey
        } = this.props;
        const tableColumnsComputed = ({tableColumns}) => tableColumnsWithActions(tableColumns, width);

        return (
            <Plugin
                name="ActionsColumn"
                dependencies={pluginDependencies}
            >
                <Getter name="tableColumns" computed={tableColumnsComputed}/>

                <Template
                    name="tableCell"
                    predicate={({tableRow, tableColumn}) =>
                        isHeadingActionsTableCell(tableRow, tableColumn)}
                >
                    <Table.Cell style={{textAlign: 'center'}}>Дії</Table.Cell>
                </Template>
                <Template
                    name="tableCell"
                    predicate={({tableRow, tableColumn}) => isActionsTableCell(tableRow, tableColumn)}
                >
                    {params => (
                        <Table.Cell {...params} row={params.tableRow.row}  style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                            {actions.map(action => {
                                if (!!params.tableRow.row){

                                    const id = params.tableRow.row[primaryKey];
                                    const row = params.tableRow;
                                    return (
                                        <IconButton  key={action.action + '_key'} onClick={() => action.action(id, row)}>
                                            {action.icon}
                                        </IconButton>
                                    )
                                }else{
                                    return (<div key={action.action + '_key'}></div>)
                                }
                            })}
                        </Table.Cell>
                    )}
                </Template>
            </Plugin>
        );
    }
}
ActionsColumn.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.PropTypes.shape({
        icon: PropTypes.node,
        action: PropTypes.func.isRequired
    })).isRequired,
    width: PropTypes.number
};
ActionsColumn.defaultProps = {
    width: 240,
};