import React, {useState, useEffect, useCallback} from 'react';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
    PagingState,
    SortingState,
    CustomPaging, DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import Loading from "../../utilities/Loader";
import {ActionsColumn} from "./ActionsColumn";
import { useConfirm } from 'material-ui-confirm';
import {Typography} from "@material-ui/core";
import {handleErrors} from "../../utilities/functions";

const VACATION_TEXT =   'Вакансія';
export default (props) => {
    const lastUpdatedKartPosition = props.lastUpdatedKartPosition;
    const URL = `https://api.mastertarif.pp.ua/index.php?r=main-interface%2Fget-unit-positions-list&tarif_id=${props.tarif_id}&unit=${props.unit}&lastUpdated=${lastUpdatedKartPosition}`;
    let columns=[];
    if (props.open){
        columns = [
            { name: 'position_name',            title: 'Посада' },
            {
                name: 'kart_name',              title: 'ПІБ',
                getCellValue: row => (row.kart_name ? row.kart_name : VACATION_TEXT),
            },
            { name: 'kart_position_quantity',   title: 'Обсяг роботи' },
            { name: 'kart_position_category',   title: 'Категорія' },
            { name: 'kart_position_rank',       title: 'Тарифний розряд' },
        ];
    }else{
        columns = [
            {
                name: 'detail',
                title: 'Посада ПІБ Категорія Ранг',
                getCellValue: row => `${row.position_name} ` + (row.kart_name ? row.kart_name : 'Вакансія') + ` ${row.kart_position_category} ${row.kart_position_rank}`,
            },
            { name: 'kart_position_quantity',   title: 'Обсяг роботи' },
            {
                name: 'main_salary',
                title: 'Оклад'
            },
            {
                name: 'accruals_1',
                title: 'Підвищення'
            },
            {
                name: 'full_salary',
                title: 'Оклад з підвищеннями'
            },
            {
                name: 'accruals_2',
                title: 'Доплати'
            },
            {
                name: 'accruals_3',
                title: 'Надбавки'
            },
            {
                name: 'total',
                title: 'Всього'
            },
        ];
    }
    const [tableColumnExtensions] = useState([
        { columnName: 'kart_name', wordWrapEnabled: true },
    ]);

    const [rows, setRows] = useState([]);
    const [sorting, setSorting] = useState([{ columnName: 'kart_name', direction: 'asc' }]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageSizes] = useState([10, 25, 50, 100]);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState();

    const changePageSize = (value) => {
        const totalPages = Math.ceil(totalCount / value);
        const updatedCurrentPage = Math.min(currentPage, totalPages - 1);

        setPageSize(value);
        setCurrentPage(updatedCurrentPage);
    };

    const getQueryString = useCallback(() => {

        let queryString = `${URL}&take=${pageSize}&skip=${pageSize * currentPage}&expand=1`;

        if (sorting.length) {
            const sortingConfig = sorting
                .map(({ columnName, direction }) => ({
                    selector: columnName,
                    desc: direction === 'desc',
                }));
            const sortingStr = JSON.stringify(sortingConfig);
            queryString = `${queryString}&sort=${escape(`${sortingStr}`)}`;
        }
        return queryString;
        // eslint-disable-next-line
    }, [URL, currentPage, pageSize, sorting]);
    useEffect(() => {
        const queryString = getQueryString();
        if (queryString !== lastQuery && !loading) {
            setLoading(true);
            fetch(queryString, {credentials: 'include'})
                .then(response => handleErrors(response))//props.logout))
                .then(response => response.json())
                .then(({ data, totalCount: newTotalCount }) => {
                        setRows(data);
                        setTotalCount(newTotalCount);
                        setLoading(false);
                })
                .catch(
                    (error) => {
                        console.log(error);
                        setLoading(false);
                    }
                );
            setLastQuery(queryString);
        }
        // eslint-disable-next-line
    }, [setLoading, setTotalCount, setRows, setLastQuery, getQueryString, lastQuery, loading]);
    let confirm = useConfirm();

    const handleDelete= (id) => {
        confirm({
                    description: 'Підвердження видалення записів позиції в тарифікаційному списку та всіх підвищень, надбавок і доплат',
                    title: 'Ви впевнені?', confirmationText:'Підтвердити',
                    cancellationText:'Скасувати',
                    confirmationButtonProps:{endIcon:<DeleteIcon />}
                }
            )
            .then(() => {setLoading(true);props.deleteKartPosition(id, setLastQuery, setLoading); })
            .catch(() => { /* ... */ });
    };

    const actions = [
        {
            icon: <DeleteIcon/>,
            action: (id) => {handleDelete(id)}
        },
        {
            icon: <EditIcon/>,
            action: (id) => {props.setFormTitle('Редагування позиції'); props.getPositionForUpdate(id, props.setShowForm); }
        },
        {
            icon: <AttachMoneyIcon />,
            action: (id, row) => {props.setFormTitle(`Розрахунок позиції ${row.row['kart_name']}`);props.getAccrualForPosition(id, props.setShowAccrualForm)}
        },

    ];
    const rowComponent = ({...restProps }) => {
        return <Table.Row {...restProps} style={{ height: "40px" }} />;
    };

    const cellComponent = ({ ...restProps }) => {
        let additional_css_for_vacation={};
        if (restProps.column.name==='kart_name' && restProps.value===VACATION_TEXT){
            additional_css_for_vacation={fontStyle: 'italic'};
        }
        return <Table.Cell {...restProps} style={{ paddingTop: "2px", paddingBottom: "2px", ...additional_css_for_vacation }} />;
    };

    const DetailFormatter = ({ row: {kart_position_id,  position_name, kart_name, kart_position_category, kart_position_rank  } }) => {
        return <span style={kart_position_id.toString()===lastUpdatedKartPosition.split('_')[0].toString() ? {color: 'green'} : {}}>
            <Typography varian={'h6'}>
              {position_name}
            </Typography>
            <span style={{fontStyle: !kart_name ? 'italic' : 'normal'}} >
                {!kart_name ? 'Вакансія' : kart_name}
            </span>
            <div>{kart_position_category} {kart_position_rank}</div>
        </span>
    };

    const KartNameFormatter = ({column, row: {kart_position_id, kart_name}}) => {
        return <span style={kart_position_id.toString()===lastUpdatedKartPosition.split('_')[0].toString() ? {color: 'green'} : {}}>
            {kart_name ? kart_name : VACATION_TEXT}
        </span>
    }

    const AccrualDetailsFormatter = ({column, row: { accruals } }) => {
        const values = Object.keys(props.general_info.schema[column.name]).map( (column_accruals_needed_id, column_accruals_key) => { //Перебор всех начислений для этого столбца
           if (!!accruals && !!accruals[column_accruals_needed_id]) {
               return <li title={props.general_info.schema[column.name][column_accruals_needed_id]} key={column_accruals_needed_id}>{accruals[column_accruals_needed_id].sum}</li>
           }
           return <span key={Math.random()}></span>;
        });
        return <span>
            <ul>{values}</ul>
        </span>
    }

    const CellAccrualDetail = props => (
        <DataTypeProvider
            for={['main_salary', 'accruals_1', 'accruals_2', 'accruals_3', 'full_salary', 'total']}
            formatterComponent={AccrualDetailsFormatter}
            {...props}
        />
    )

    const CellDetail = props => (
        <DataTypeProvider
            for={['detail']}
            formatterComponent={DetailFormatter}
            {...props}
        />
    );

    const CellName = props => (
        <DataTypeProvider
            for={['kart_name']}
            formatterComponent={KartNameFormatter}
            {...props}
        />
    );

    return (
        <Paper style={{ position: 'relative' }} elevation={3} >
            {loading && <Loading />}
            <Grid
                rows={rows}
                columns={columns}
            >
                <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                />
                <CellDetail />
                <CellName />
                <CellAccrualDetail />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={changePageSize}
                />
                <CustomPaging
                    totalCount={totalCount}
                />
                <Table
                   columnExtensions={tableColumnExtensions}
                   rowComponent={rowComponent}
                   cellComponent={cellComponent}
                />
                <TableHeaderRow contentProps={{align:'center'}} />
                <ActionsColumn actions={actions} width={160} primaryKey={'kart_position_id'}/>
                <PagingPanel
                    pageSizes={pageSizes}
                />
            </Grid>
        </Paper>
    );
};
