import React from "react";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = () => {
    return <Grid container item justify={'center'} spacing={2} xs={12}>
        <CircularProgress />
    </Grid>
}

export default Loader;