import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {login_from_local} from "../redux/authReducer";

let mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    redirect: state.auth.redirect,
});

export const withAuthRedirect = (Component) => {
    class RedirectComponent extends React.Component {
        render() {
            let isAuth=this.props.isAuth;

            if (!isAuth) {
                let try_auth_from_local_storage = localStorage.getItem('logged_in');
                let try_expires_local_storage = localStorage.getItem('expires');
                let try_username_from_local_storage = localStorage.getItem('username');
                if (try_auth_from_local_storage) {
                    if (try_expires_local_storage*1000>Date.now()){
                        this.props.login_from_local({username: try_username_from_local_storage});
                        isAuth=true;
                    }
                }
            }
            if (!isAuth){
                return <Redirect to='/login' />
            }
            return <Component {...this.props} />
        }
    }
    let ConnectedAuthRedirectComponent = connect(mapStateToProps, {login_from_local})(RedirectComponent);
    return ConnectedAuthRedirectComponent
}

