import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Paper} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
const header_text={
    'main_salary'   : 'Посадовий оклад',
    'accruals_1'    : 'Підвищення посадового окладу',
    'full_salary'   : 'Посадовий оклад з підвищеннями',
    'accruals_2'    : 'Доплати, що мають обов`язковий характер',
    'accruals_3'    : 'Надбавки, що мають обов`язковий характер',
    'total'         : 'Місячний фонд заробітної плати',
};
let k=0;
export default (props) => {

    // const useStyles = makeStyles((theme) => ({
    //     tableData:{
    //         position:'absolute',
    //         overflow:'scroll',
    //         height:'100%',
    //     },
    //     tableDataHeaderCell:{
    //         textAlign: 'center',
    //         border: '1px solid black',
    //     },
    //     tableDataUnitCell:{
    //         border: '1px solid black',
    //         fontSize: '120%',
    //         fontWeight: 'bold',
    //     },
    //     tableDataCell:{
    //         border: '1px solid black',
    //     },
    //     tableDataCellCenter:{
    //         border: '1px solid black',
    //         textAlign: 'center'
    //     },
    //     tableDataCellRight: {
    //         border: '1px solid black',
    //         textAlign: 'right'
    //     },
    // }));
    let cols_count=4;
    const classes = useStyles();
    return <TableContainer component={Paper} className={classes.tableData}>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableDataHeaderCell} rowSpan={2}>№</TableCell>
                    <TableCell className={classes.tableDataHeaderCell} rowSpan={2}>Назва структурного підрозділу, посада, кваліфікаційна категорія (розряд)</TableCell>
                    <TableCell className={classes.tableDataHeaderCell} rowSpan={2}>Прізвище, ім'я та по батькові</TableCell>
                    <TableCell className={classes.tableDataHeaderCell} rowSpan={2}>Тарифний розряд</TableCell>
                    {Object.keys(props.general_info.schema_sorted).map(function(key) {
                        cols_count=cols_count+props.general_info.schema_sorted[key].length;
                        return <TableCell
                            key={key}
                            className={classes.tableDataHeaderCell}
                            rowSpan={props.general_info.schema_sorted[key].length > 1 ? 1 : 2}
                            colSpan={props.general_info.schema_sorted[key].length > 1 ? props.general_info.schema_sorted[key].length : 1}
                        >
                            {header_text[key]}
                        </TableCell>
                    })}
                </TableRow>
                <TableRow>
                    {Object.keys(props.general_info.schema_sorted).map(function(key) {
                        if (props.general_info.schema_sorted[key].length > 1){
                            return Object.keys(props.general_info.schema_sorted[key]).map(function(key2) {
                                return <TableCell key={key2}  className={classes.tableDataHeaderCell}>
                                    {props.general_info.schema_sorted[key][key2]['param']}
                                </TableCell>
                            })
                        }
                        return '';
                    })}
                </TableRow>
            </TableHead>
                {   Object.keys(props.data).map(function(key) {
                    return <TableBody key={'body_'+key}>
                        <TableRow key={'row_'+key}>
                            <TableCell
                                key={key}
                                colSpan={cols_count}
                                className={classes.tableDataUnitCell}
                            >
                                {key}
                            </TableCell>
                        </TableRow>
                        {Object.keys(props.data[key]).map(function(key2) {
                            return Object.keys(props.data[key][key2]).map(function(key3) {
                                k++;
                                return <TableRow key={'row_'+key3}>
                                    <TableCell
                                        key={key3+'_num'}
                                        className={classes.tableDataCell}
                                    >
                                        {k}
                                    </TableCell>
                                    <TableCell
                                        key={key3+'_position'}
                                        className={classes.tableDataCell}
                                    >
                                        {props.data[key][key2][key3]['position']} {props.data[key][key2][key3]['category']}
                                    </TableCell>
                                    <TableCell
                                        key={key3+'_name'}
                                        className={classes.tableDataCell}
                                    >
                                        {props.data[key][key2][key3]['name']}
                                    </TableCell>
                                    <TableCell
                                        key={key3+'_rank'}
                                        className={classes.tableDataCellCenter}
                                    >
                                        {props.data[key][key2][key3]['rank']}
                                    </TableCell>
                                    {Object.keys(props.general_info.schema_sorted).map(function(key5) {
                                                return Object.keys(props.general_info.schema_sorted[key5]).map(function(key6) {
                                                    let accrual_key=props.general_info.schema_sorted[key5][key6]['key'];
                                                    return <TableCell key={key3+'_'+key6}  className={classes.tableDataCellRight}>
                                                        {!!props.data[key][key2][key3]['accruals'][accrual_key] ? props.data[key][key2][key3]['accruals'][accrual_key] : ''}
                                                    </TableCell>
                                                })
                                        })
                                    }

                                </TableRow>
                            });

                        })}
                    </TableBody>;
                })}
        </Table>
    </TableContainer>
}

//'id'        =>$kartPosition->kart_position_id,
//                         'name'      =>empty($karts[$kartPosition->id_kart]) ? 'Вакансія' : $karts[$kartPosition->id_kart]->kart_name,
//                         'position'  =>$positions[$kartPosition->id_position]->position_name,
//                         'unit'      =>$positions[$kartPosition->id_position]->position_unit,
//                         'order'     =>$kartPosition->kart_position_order,
//                         'quantity'  =>$kartPosition->kart_position_quantity,
//                         'category'  =>$kartPosition->kart_position_category,
//                         'rank'      =>$kartPosition->kart_position_rank,
//                         'sub_unit'  =>$kartPosition->kart_position_sub_unit,
//                         'group'     =>$positions[$kartPosition->id_position]->position_group,

// 'main_salary'   => /* Посадовий оклад      */ [ 1  => 'Посадовий оклад'],
// 'accruals_1'    => /* Підвищення           */ [ 2  => 'за кваліфікаційну категорію керівникам та їх заступникам',
//     12 => 'за завідування',
//     13 => 'за старшинство',
//     14 => 'за санітарний транспорт',
//     3  => 'за оперативні втручання',
//     4  => 'за диплом з відзнакою',
//     5  => 'інщі підвищення, передбачені пунктом 2.2',
//     6  => 'у зв`язку зі шкідливими і важкими умовами оплати праці',
//     7  => 'інші підвищення '],
// 'full_salary'   => /* Оклад з підвищеннями */ [ 8  => 'Посадовий оклад з підвищеннями'],
// 'accruals_2'    => /* Доплати              */ [ 9  => 'Доплати, що мають обов`язковий характер '],
// 'accruals_3'    => /* Надбавки             */ [ 10 => 'за почесне звання',
//     15 => 'вислуга років',
//     16 => 'за спецконтингент',
//     17 => 'за майстерність',
//     18 => 'за класність',
//     19 => 'за тривалість безперервної роботи'],
// 'total'         => /* Фонд зар. платні     */ [ 11 =>  'Місячний фонд заробітної плати']
