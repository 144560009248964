import React from 'react'
import HomeScreen from './HomeScreen';
import { connect } from 'react-redux';
import {withAuthRedirect} from "../../utilities/withAuthRedirect";
import {compose} from "redux";
import {
    deleteTarif,
    getOurRsList,
    getTarifForUpdate,
    setCurrentTarifDispatch,
    setRedirect, setTarifForCreate
} from "../../redux/tarifReducer";
import {Redirect} from "react-router-dom";

class HomeScreenContainer extends React.Component{
    render () {
        //При створенні чи зміні тарифікаційного листа - переходити в його інтерфейс
        if (!!this.props.redirect_url){
            return <Redirect to={this.props.redirect_url} />;
        }

        this.props.getOurRsList();
        return <HomeScreen {...this.props}/>
    }
}
const mapStateToProps = (state) => ({
    login: state.auth.login,
    tarif_types: state.tarif.typeTarifs,
    redirect_url: state.tarif.redirect,
});

export default compose (connect(mapStateToProps, {setTarifForCreate, setRedirect, getOurRsList, setCurrentTarifDispatch, getTarifForUpdate, deleteTarif}), withAuthRedirect)(HomeScreenContainer);