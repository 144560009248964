import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import authReducer from "./authReducer"
import { reducer as formReducer } from 'redux-form'
import tarifReducer from "./tarifReducer";
import mainInterfaceReducer from "./mainInterfaceReducer";

let rootReducer = combineReducers({
    auth: authReducer,
    tarif: tarifReducer,
    mainInterface: mainInterfaceReducer,
    form: formReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));
window.store = store;
//TODO убрать для Production 

export default store