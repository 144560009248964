import {stopSubmit} from "redux-form";
import {getFormData, handleErrors} from "../utilities/functions";

let initialState = {
    redirect: null,
    isLoading: false,
    current_tarif: null,
    ourRsList : [],
    typeTarifList: [
        { value: "1", label: 'Медична'},
        { value: "2", label: 'Педагогічна'},
        { value: "3", label: 'Виховательска'},
        { value: "4", label: 'Штатний розклад'}
    ],
    typeTarifs: {1: 'Медична', 2: 'Педагогічна', 3: 'Виховательска', 4: 'Штатний розклад'},
    tarif_model_for_form: {}
};
const ACTION_SET_CURRENT_TARIF      =   'ACTION_SET_CURRENT_TARIF';
const ACTION_SET_LOADING            =   'ACTION_SET_LOADING';
const ACTION_SET_LOADING_FINISHED   =   'ACTION_SET_LOADING_FINISHED';
const ACTION_SET_ERROR              =   'ACTION_SET_ERROR';
const ACTION_GET_OUR_RS_LIST        =   'ACTION_GET_OUR_RS_LIST';
const ACTION_GET_TARIF_FOR_UPDATE   =   'ACTION_GET_TARIF_FOR_UPDATE';
const ACTION_SET_REDIRECT           =   'ACTION_SET_REDIRECT';
const ACTION_SET_TARIF_FOR_CREATE   =   'ACTION_SET_TARIF_FOR_CREATE';

const tarifReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_GET_OUR_RS_LIST:
            return {
                ...state,
                ourRsList: action.ourRsList
            };
        case ACTION_SET_CURRENT_TARIF:
            return {
                ...state,
                current_tarif: action.tarif_id,
            };
        case ACTION_SET_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ACTION_SET_LOADING_FINISHED:
            return {
                ...state,
                isLoading: false
            };
        case ACTION_SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case ACTION_GET_TARIF_FOR_UPDATE:
            return {
                ...state,
                tarif_model_for_form: action.tarif_data
            };
        case ACTION_SET_REDIRECT:
            return {
                ...state,
                redirect: action.redirect
            };
        case ACTION_SET_TARIF_FOR_CREATE:
            return {
                ...state,
                tarif_model_for_form: {}
            };
        default:
            return state;
    }
};

const setLoading = () => ({ type: ACTION_SET_LOADING });
export const setRedirect = (redirect) => ({ type: ACTION_SET_REDIRECT, redirect });
export const setTarifForCreate = () => ({ type: ACTION_SET_TARIF_FOR_CREATE});
export const setCurrentTarif = (tarif_id) => ({ type: ACTION_SET_CURRENT_TARIF, tarif_id });
const setLoadingFinished = () => ({ type: ACTION_SET_LOADING_FINISHED });
export const setCurrentTarifDispatch = (tarif_id) => {
    return (dispatch) => {
        dispatch({ type: ACTION_SET_CURRENT_TARIF, tarif_id});
    }
};
export const getTarifForUpdate = (id) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=tbl-tarif/get-tarif-for-update&id=${id}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((tarif_data) => {
                    dispatch({type: ACTION_GET_TARIF_FOR_UPDATE, tarif_data});
                }
            ).catch(function (err) {
        });
    }
};
export const deleteTarif = (id, setLoading) => {
    return (dispatch) => {
        fetch(`https://api.mastertarif.pp.ua/index.php?r=tbl-tarif/delete-api&id=${id}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((reponse) => {
                    setLoading(false);
                }
            ).catch(function (err) {
        });
    }
};
export const getOurRsList = () => {
    return (dispatch) => {
        fetch('https://api.mastertarif.pp.ua/index.php?r=main-interface/get-our-rs-list', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((jsonData) => {
                    dispatch({type: ACTION_GET_OUR_RS_LIST, ourRsList: jsonData});
                }
            ).catch(function (err) {
        });
    }
};
export const saveTarif = (data) => {
    return (dispatch) => {
        dispatch(setLoading());
        // console.log(data);
        // console.log(getFormData(data));
        fetch('https://api.mastertarif.pp.ua/index.php?r=tbl-tarif/save', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData(data)
        })
            .then(response => handleErrors(response))
            .then(response => response.json())
            .then((jsonData) => {
                    dispatch(setLoadingFinished());
                    dispatch(setCurrentTarif(jsonData));
                    dispatch(setRedirect(`/main-interface/${jsonData}`));
                }
            ).catch(function (err) {
            if (err.message) {
                let stop_action= stopSubmit('tarif-form', {username: 'Помилка збереження'});
                dispatch(stop_action);
                return false;
            }
            dispatch(setLoadingFinished());
        });
    }
};


export default tarifReducer;