import {stopSubmit} from "redux-form";
import {getFormData} from "../utilities/functions";

const ACTION_LOGIN_SUCCESS = 'ACTION_LOGIN_SUCCESS';
const ACTION_LOGOUT = 'ACTION_LOGOUT';
const ACTION_SET_LOADING = 'ACTION_SET_LOADING';
const ACTION_SET_LOADING_FINISHED = 'ACTION_SET_LOADING_FINISHED';
const ACTION_SET_ERROR = 'ACTION_SET_ERROR';

let initialState = {
    isLoading: false,
    userId: null,
    email: null,
    login: null,
    isAuth: false,
    error: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_LOGIN_SUCCESS:
            return {
                ...state,
                email: action.data.email,
                login: action.data.login,
                isAuth: action.data.isAuth,
            };
        case ACTION_LOGOUT:
            return {
                ...state,
                email: null,
                login: null,
                isAuth: false,
                isLoading: false
            };
        case ACTION_SET_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ACTION_SET_LOADING_FINISHED:
            return {
                ...state,
                isLoading: false
            };
        case ACTION_SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
const setLoading = () => ({ type: ACTION_SET_LOADING });
const setLoadingFinished = () => ({ type: ACTION_SET_LOADING_FINISHED });

const setError = (error) => (dispatch) => {
    dispatch({ type: ACTION_SET_ERROR, error });
};

export const logout = () => (dispatch) => {
    dispatch({ type: ACTION_LOGOUT });
    localStorage.removeItem('username');
    localStorage.removeItem('logged_in');
    localStorage.removeItem('expires');
};

export const login_from_local = (data) => (dispatch) =>{
    dispatch({
        type: ACTION_LOGIN_SUCCESS, data: {
            login: data.username,
            isAuth: true,
            email: 'example@domain.com'
        }
    });
}

export const login = (data) => {
    return (dispatch) => {
        dispatch(setLoading());
        fetch('https://api.mastertarif.pp.ua/index.php?r=site/login-api', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: getFormData(data)
        })
            .then(response => response.json())
            .then((jsonData) => {
                if (jsonData.message === 'Wrong credentials') {
                    //this.setState({'error': 'Не вірна пара логін/пароль'});
                    let stop_action= stopSubmit('login-form', {username: 'Не вірна пара логін/пароль', password:'Не вірна пара логін/пароль'});
                    dispatch(stop_action);
                    dispatch(setLoadingFinished());
                    return false;
                }
                dispatch({
                    type: ACTION_LOGIN_SUCCESS, data: {
                        login: jsonData.username,
                        isAuth: true,
                        email: 'example@domain.com'
                    }
                });
                localStorage.setItem('username', jsonData.username);
                localStorage.setItem('logged_in', 'true');
                localStorage.setItem('expires', jsonData.expires);

                setError(false);
                dispatch(setLoadingFinished());
            }
            ).catch(function (err) {
            // debugger
                if (err.message === 'Wrong credentials') {
                    //this.setState({'error': 'Не вірна пара логін/пароль'});
                    let stop_action= stopSubmit('login-form', {username: 'Не вірна пара логін/пароль', password:'Не вірна пара логін/пароль'});
                    dispatch(stop_action);
                    return false;
                }
                dispatch(setLoadingFinished());
            });
    }
};

export default authReducer;