import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Box, Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {
    addAccrualToPosition,
    //calcOne,
    deleteAccrualFromPosition,
    getAccrualForPosition,
    saveAccruals, setKartPositionAccrualModelForForm
} from "../../redux/mainInterfaceReducer";
import AddBoxIcon from '@material-ui/icons/AddBox';
import FunctionsIcon from '@material-ui/icons/Functions';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import KartPositionAccrualForm from "./KartPositionAccrualForm";

const Accrual = ({
    saveAccruals,
    ...props
}) => {
    return <Box my="20px">
        <Grid container justify={'center'} spacing={2}>
            <Paper elevation={3}>
                <Box px="5px">
                    <Grid container justify={'center'}>
                        <Grid container item xs={12} justify={'center'}>
                            <Typography variant="h6">
                                {props.formTitle} {<IconButton color={'primary'} onClick={() => {
                                props.getAccrualForPosition(props.selected_kart_position, () => {
                                }, true)
                            }}><FunctionsIcon/></IconButton>}
                                {
                                    <IconButton color={'secondary'} onClick={() => {
                                            props.setShowAccrualForm(false)
                                        }
                                    }><CancelIcon /></IconButton>
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AccrualsForm id_position={props.selected_kart_position} onSubmit={saveAccruals} enableReinitialize={true} {...props}/>
                        </Grid>
                        <Grid container item xs={12} justify={'center'}>
                            <Button autoFocus color={'secondary'} onClick={() => {
                                props.setShowAccrualForm(false);
                            }}>Закрити</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    </Box>
};

const AccrualsForm = (props) => {
    const [editablePercentRow, setEditablePercentRow] = useState(false);
    return <Grid container item xs={8} >
            <table>
                <thead>
                <tr>
                    <th style={{width: '100px'}}>Дії</th>
                    <th style={{width: '110px'}}>%</th>
                    <th>Сума</th>
                    <th>Вид</th>
                </tr>
                </thead>
                <tbody>
                    {Object.keys(props.schema).map(function(key, index) {
                        return Object.keys(props.schema_sorted[key]).map(function (key_accrual_sorted, index_accrual) {
                            let key_accrual=props.schema_sorted[key][key_accrual_sorted]['key'];
                            return <tr key={key_accrual}>
                                <td>
                                    {!!props.values[key_accrual] ?
                                        <span>
                                            <IconButton  onClick={() => {props.deleteAccrualFromPosition(props.values[key_accrual].kart_position_accrual_id)}}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </span>
                                    :
                                        <IconButton onClick={() => {props.addAccrualToPosition(key_accrual, props.selected_kart_position)}}>
                                            <AddBoxIcon />
                                        </IconButton>
                                    }
                                </td>
                                <td style={{textAlign: 'right'}}>
                                    {!!props.values[key_accrual] ?
                                        key_accrual===editablePercentRow ?
                                                <KartPositionAccrualForm id_position={props.selected_kart_position} setEditablePercentRow={setEditablePercentRow}/>
                                            :
                                                editablePercentRow !==false && key_accrual!==editablePercentRow ?
                                                    <Button onClick={()=>{alert('Спочатку збережіть вже обраний вид')}}> {props.values[key_accrual].kart_position_accrual_percent}</Button>
                                                    :
                                                        <Button onClick={()=> {
                                                            props.setKartPositionAccrualModelForForm({
                                                                kart_position_accrual_percent: props.values[key_accrual].kart_position_accrual_percent,
                                                                kart_position_accrual_id: props.values[key_accrual].kart_position_accrual_id,
                                                            });
                                                            setEditablePercentRow(key_accrual);}
                                                        }>
                                                            {props.values[key_accrual].kart_position_accrual_percent}
                                                        </Button>
                                        :
                                        ''
                                    }
                                </td>
                                <td style={{textAlign: 'right'}}>
                                    {!!props.values[key_accrual] ?
                                        props.values[key_accrual].kart_position_accrual_sum
                                    :
                                        ''
                                    }
                                </td>
                                <td>
                                    {props.schema_sorted[key][key_accrual_sorted]['param']}
                                </td>
                            </tr>
                        })
                    })}
                </tbody>
            </table>
    </Grid>
};

//const AccrualsReduxForm = reduxForm({ form: 'accruals-form'})(AccrualsForm);

const mapStateToProps = (state) => ({
    isLoading: state.tarif.isLoading,
    values: state.mainInterface.accruals_model_for_form,
    schema: state.mainInterface.general_info.schema,
    schema_sorted: state.mainInterface.general_info.schema_sorted,
    selected_kart_position: state.mainInterface.selected_kart_position
});

export default connect(mapStateToProps, {
    saveAccruals,
    //calcOne,
    getAccrualForPosition,
    addAccrualToPosition,
    deleteAccrualFromPosition,
    setKartPositionAccrualModelForForm
})(Accrual);