import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {saveKartPositionAccrual} from "../../redux/mainInterfaceReducer";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from '@material-ui/icons/Done';

const KartPositionAccrual = ({
    savePosition,
    ...props
}) => {
    return <KartPositionAccrualReduxForm onSubmit={(data) => {props.saveKartPositionAccrual(data, props.id_position, props.setEditablePercentRow)}} enableReinitialize={true} {...props}/>
};

const KartPositionAccrualForm = (props) => {
    return <form onSubmit={ props.handleSubmit} autoComplete="off">
            <Field style={{width: '50px'}} component='input' type='text' name='kart_position_accrual_percent'  />
            <Field component="input" type="hidden" name='kart_position_accrual_id'/>
            <IconButton  variant="contained" type={'submit'} color={'primary'}><DoneIcon /></IconButton>
    </form>
};

const KartPositionAccrualReduxForm = reduxForm({ form: 'kart-position-accrual-form'})(KartPositionAccrualForm);

const mapStateToProps = (state) => ({
    initialValues    : state.mainInterface.kart_position_accrual_model_for_form,
});

export default connect(mapStateToProps, {
        saveKartPositionAccrual
    })(KartPositionAccrual);