import React from 'react'
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {/*checkAccessFromStorage,*/ login} from "../../redux/authReducer";
import { Redirect } from 'react-router-dom';
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import {required} from "../../utilities/validators";
import {MaterialCheckbox, MaterialTextField} from "../../redux/FormsControls";
import { makeStyles } from '@material-ui/core/styles';
import {AccountCircle} from "@material-ui/icons";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import classNames from './Login.module.css';
import Loader from "../../utilities/Loader";

const Login = (props) => {
    if (props.isAuth) {
        return <Redirect to='/home' />;
    }
    return <div className={classNames.form_login}>
        {props.isLoading ?
            <Loader/>
            :
            <LoginReduxForm onSubmit={props.login}/>
        }
    </div>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
}));



const LoginForm = (props) => {
    const classes = useStyles();
    return <form onSubmit={props.handleSubmit}>
        <Grid container justify={'center'} spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper className={classes.padding}>
                    <div className={classes.margin}>
                        <Grid container justify={'center'} alignItems="flex-end">
                            <Grid item>
                                <Typography variant="h5" gutterBottom>
                                    Вхід в систему
                                    </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify={'center'} alignItems="flex-end">
                            <Grid item >
                                <AccountCircle />
                            </Grid>
                            <Grid item xs={10}>
                                <Field fullWidth name={'username'} label='Логін' component={MaterialTextField} validate={[required]}/>
                            </Grid>
                        </Grid>
                        <Grid container justify={'center'} alignItems="flex-end">
                            <Grid item >
                                <FingerprintIcon />
                            </Grid>
                            <Grid item xs={10}>
                                <Field fullWidth name={'password'} label='Пароль' component={MaterialTextField} type='password' />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="flex-end" justify="center">
                            <Grid item md={10} sm={11} xs={10}>
                                <Field type={'checkbox'} name={'rememberMe'} label='Запам`ятати мене' component={MaterialCheckbox} /></Grid>
                        </Grid>
                        <Grid container alignItems="flex-end" justify="center">
                            <Grid item md={10} sm={11} xs={10}>
                                <Button disableFocusRipple disableRipple style={{ textTransform: "none" }} variant="text" color="primary">Забули пароль?</Button>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" style={{ marginTop: '10px' }}>
                            <Button type={'submit'}>Вхід</Button>
                        </Grid>
                    </div>
                </Paper>
            </Grid>
        </Grid>
    </form>
}
const LoginReduxForm = reduxForm({ form: 'login-form' })(LoginForm);

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    isLoading: state.auth.isLoading,
});

export default connect(mapStateToProps, { login })(Login);