import React, {useState} from "react";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MainInterfaceIndex from "./MainInterfaceIndex";
import GridViewUnitPositionsList from "../Common/GridViewUnitPositionsList";
import Modal from "@material-ui/core/Modal";
import PositionForm from "./PositionForm";
import AccrualsForm from "./AccrualsForm";
import HomeIcon from '@material-ui/icons/Home';
import TarifTable from "./TarifTable";
import {formatDate} from "../../utilities/functions";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    modalScrollable:{
        position:'absolute',
        top:'10%',
        left:'10%',
        overflow:'scroll',
        height:'100%',
        display:'block'
    },
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',

    },
    drawerHeaderUnitPositionsList: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    headerCaption: {
        textAlign: 'center'
    }
}));

export default (props) => {
    const [showForm, setShowForm] = useState(false);
    const [showAccrualForm, setShowAccrualForm] = useState(false);
    const [lastUpdatedKartPosition, setLastUpdatedKartPosition] = useState ('0_0');
    const [formTitle, setFormTitle] = useState('Оклад, підвищення, надбавки та доплати');
    const classes = useStyles();
    const theme = useTheme();
    const handleDrawerClose = () => {
        props.setOpenLeftBar(false);
    };
    const { currentUnit, setCurrentUnit } = props;


    function addNewPosition()
    {
        let kart_position_date_start_work=formatDate(props.general_info.tarif_info.tarif_date);
        props.setEmptyPositionForCreate(props.tarif_id, kart_position_date_start_work);
        setShowForm(true);
    }

    return <div>
        {showAccrualForm ?
            <Modal
                // maxWidth={8}
                // fullWidth={true}
                open={showAccrualForm}
                onClose={() => setShowAccrualForm(false)}
                aria-labelledby="modal-accruals-of-kart-position-form"
                aria-describedby="modal-view-for-accruals-of-kart-position-record"
                className={classes.modalScrollable}
            >
                <div>
                    <AccrualsForm
                        formTitle={formTitle}
                        setShowAccrualForm={setShowAccrualForm}
                    />
                </div>
            </Modal>
            : ''
        }
        {showForm ?
            <Modal
                open={showForm}
                onClose={() => setShowForm(false)}
                aria-labelledby="modal-kart-position-form"
                aria-describedby="modal-view-for-kart-position-record"
                className={classes.modalScrollable}
            >
                <div>
                    <PositionForm
                        searchKarts={props.searchKarts}
                        searchPositions={props.searchPositions}
                        kart_options={props.kart_list_for_autocomplete}
                        position_options={props.position_list_for_autocomplete}
                        currentUnit={currentUnit}
                        general_info={props.general_info}
                        formTitle={formTitle}
                        setShowForm={setShowForm}
                        lastUpdatedKartPosition={lastUpdatedKartPosition}
                        setLastUpdatedKartPosition={setLastUpdatedKartPosition}
                        setCurrentUnit={setCurrentUnit}
                    />
                </div>
            </Modal>
            : ''}

        {props.data_tarif_table === null ?
            <div>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={props.open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={() => {setCurrentUnit(false);}}>
                            <HomeIcon />
                        </IconButton>
                        <Typography variant={'h6'}>Підрозділи</Typography>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {!!props.general_info.unit_list ? props.general_info.unit_list.map((text, index) => (
                            <ListItem button key={text.position_unit}>
                                {
                                    (currentUnit===text.position_unit) ?
                                        <ListItemText onClick={() => setCurrentUnit(text.position_unit)} secondary={text.position_unit} /> :
                                        <ListItemText onClick={() => setCurrentUnit(text.position_unit)} primary={text.position_unit} />
                                }
                            </ListItem>
                        )):
                            <ListItem button key={'Не створено записів'}>
                                <ListItemText primary={'Не створено записів'} />
                            </ListItem>
                        }
                    </List>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: props.open,
                    })}
                >
                    <div className={classes.drawerHeaderUnitPositionsList}>
                        <Typography variant={'h6'}>
                            <IconButton onClick={addNewPosition}>
                                {<AddCircleOutlineIcon/>}
                            </IconButton>
                            {!!currentUnit ? `Підрозділ: ${currentUnit}` : ''}
                        </Typography>
                    </div>
                    {!currentUnit ? <MainInterfaceIndex
                        general_info={props.general_info}
                        calc_list={props.calc_list}
                        tarif_id={props.tarif_id}
                        headerCaptionClass={classes.headerCaption}
                    /> : <GridViewUnitPositionsList
                        logout={props.logout}
                        tarif_id={props.tarif_id}
                        general_info={props.general_info}
                        unit={currentUnit}
                        open={props.open}
                        setShowForm={setShowForm}
                        getPositionForUpdate={props.getPositionForUpdate}
                        setFormTitle={setFormTitle}
                        setShowAccrualForm={setShowAccrualForm}
                        lastUpdatedKartPosition={lastUpdatedKartPosition}
                        getAccrualForPosition={props.getAccrualForPosition}
                        deleteKartPosition={props.deleteKartPosition}
                    />}
                </main>
            </div>
        :
            <TarifTable 
                general_info={props.general_info}
                data={props.data_tarif_table}
            />
        }
    </div>
}